<template>
  <div class="section_wrap pt-50" style="padding-bottom: 70px">
    <div class="ml-20 mr-20" style="flex: 1">
      <div class="under-line pb-10 flex-row title">
        <h3 class="mr-10">{{ program.title }}</h3>
        <h6>마피아 서비스의 공지사항을 확인하세요.</h6>
      </div>

     <ul class="mt-30 ml-30">
        <li class="under-line mb-20">
          <h4 class="text-black">{{ notice_title }}</h4>
          <div style="display: flex; justify-content: space-between; flex-direction: row; width: 220px;" class="mt-10">
            <div class="text-gray">{{ notice_reg_date }}</div>
            <div class="font-weight-bold text-red" v-if="notice_new_fg === 'Y'">NEW</div>
          </div>
        </li>

       <li class="under-line mb-20 pt-10 pb-20" v-html="notice_contents">
       </li>

       <div class="mt-25" style="display: flex; justify-content: end;">
         <button class="btn-inline radius-30 pa-10-20" @click="$router.back()">목록</button>
       </div>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: "bbs_detail"
  , props: ['user']
  , data: function () {
    return {
      program: {
        name: '커뮤니티'
        , title: '공지사항'
        , type: 'mypage'
      }
      , notice_number : this.$route.query.notice_number
      , notice_title: this.$route.query.notice_title
      , notice_reg_date: this.$route.query.notice_reg_date
      , notice_new_fg: this.$route.query.notice_new_fg
      , notice_contents: ''
    }
  }
  , methods: {
    viewItem: async function () {

      try {
        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_notice_info
          , data: {
            member_number: this.user.member_number
            , notice_number: this.notice_number
          }
          , type: true
        })
        if (result.success) {
          this.notice_contents = result.data.notice_contents
        } else {
          throw result.message
        }


      } catch (e) {
        console.log(e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.$bus.$emit('on', false)
      }
    }
  }

  , created() {
    this.$emit('onLoad', this.program)
    this.viewItem()
  }
}
</script>

<style scoped>
.section_wrap {
  display: flex;
  flex-wrap: wrap;
}

h6 {
  line-height: 260%;
}

li.under-line {
  padding-bottom: 10px;
}

.title {
  border-bottom: 1px solid #b2b2b2;
  box-sizing: content-box
}

.text-gray {
  color: var(--light-Gray03);
}

.text-red {
  color: var(--red);
}

.text-black {
  color: var(--black);
}

.detail_title {
  width: 35px;
  height: 35px;
  line-height: 200%;
  background-color: #e5e5e5;
  border-radius: 20px
}

.btn-inline {
  background-color: white;
  color: var(--blue02);
  border: 2px solid var(--blue02); /* Green */
}

.btn-inline:hover {
  background-color: var(--blue02) !important;
  color: white;
}


.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

</style>